<template>
  <router-link
    :to="`/blog${edit ? '/edit/' + post.id : '/' + post.slug}`"
    class="blogCard"
    :class="{ 'pointer-event-none': preview }"
  >
    <div class="blogCard__image">
      <picture>
        <source
          type="image/webp"
          :srcset="`${pathToImage(post.slug, post.image)}.webp`"
        />
        <img
          :src="`${pathToImage(post.slug, post.image)}.png`"
          :alt="`${post.slug} image`"
        />
      </picture>
    </div>
    <div class="blogCard__content">
      <div>
        <div class="d-flex align-items-center">
          <div class="blogCard__data">
            <data-formatted date-format="dd.MM.yyyy" :data="post.date" />
          </div>
          <div v-if="!!post.expert" class="blogCard__author">
            by
            <span class="text-decoration-underline">
              <short-name
                :name="`${post.expert.first_name} ${post.expert.last_name}`"
              />
            </span>
          </div>
        </div>
        <div class="blogCard__text">
          {{ post.translations[0].title }}
        </div>
      </div>
      <div class="d-flex align-items-end justify-content-between">
        <base-button class="blogCard__latestPostBtn">
          {{ edit ? $t("editExpertBtn") : $t("readMore") }}
        </base-button>
        <div class="d-flex overflow-hidden flex-wrap justify-content-start">
          <div v-for="tag in post.tags" :key="tag.id" class="blogCard__tag">
            {{ tag.translations[0].name }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Arrow from "@/components/UI/icons/arrows/Arrow";
import DataDay from "@/components/components-helpers/DataDay";
import BaseButton from "@/components/UI/buttons/BaseButton";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import ShortName from "@/components/components-helpers/ShortName";

export default {
  props: {
    post: Object,
    uploadPath: String,
    edit: false,
    preview: false,
  },
  name: "BlogCard",
  components: { ShortName, DataFormatted, BaseButton, DataDay, Arrow },
  methods: {
    pathToImage(slug, imageName) {
      return `${this.uploadPath}/posts/${slug}/${imageName}-500`;
    },
  },
};
</script>

<style></style>
