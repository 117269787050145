<template>
  <table class="w-100">
    <tbody>
      <tr class="tagTable__header">
        <th class="col-1">№</th>
        <th class="col-10">{{ $t("Category") }}</th>
        <th class="col-1"></th>
      </tr>
      <tr v-for="(tag, i) in tagList" class="tagTable__item">
        <td>
          {{ i + 1 }}
        </td>
        <td class="row">
          <div class="col-6">
            <base-input
              type="text"
              @change="createTag(tag)"
              v-model="tag.translations[0].name"
            >
              {{ $t("EnglishName") }}
            </base-input>
          </div>
          <div class="col-6">
            <base-input
              type="text"
              @change="createTag(tag)"
              v-model="tag.translations[1].name"
            >
              {{ $t("FrenchName") }}
            </base-input>
          </div>
        </td>
        <td>
          <base-button
            class="popup__remove-logo-btn w-max-content"
            @click="removeTag(tag.id)"
          >
            {{ $t("RemoveTag") }}
          </base-button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="tagTable__footer">
    <base-button @click="addTagRow()">
      {{ $t("AddNewTag") }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import { useToast } from "vue-toastification";

export default {
  name: "TagsPage",
  components: { BaseInput, BaseButton },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      tagList: [
        {
          translations: [
            {
              language_id: null,
              name: null,
            },
            {
              language_id: null,
              name: null,
            },
          ],
        },
      ],
      post: null,
      blogPosts: null,
    };
  },
  async mounted() {
    this.tagList = await this.getTagList();
  },
  methods: {
    async getTagList() {
      return this.$http.get(`/blog/tags/translations`).then(({ data }) => {
        return data.body;
      });
    },
    createTag(tag) {
      if (tag.translations[0].name && tag.translations[1].name) {
        if (tag.id) {
          this.patchTag(tag);
          return;
        }
        this.$http
          .post("/blog/tags", {
            translations: [
              {
                language_id: 1,
                name: tag.translations[0].name,
              },
              {
                language_id: 2,
                name: tag.translations[1].name,
              },
            ],
          })
          .then(() => {
            this.toast.success(this.$t("successfulSaveData"));
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.toast.error(err.response.data.message);
            } else {
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    patchTag(tag) {
      this.$http
        .patch(`/blog/tags/${tag.id}`, {
          translations: [
            {
              language_id: 1,
              name: tag.translations[0].name,
            },
            {
              language_id: 2,
              name: tag.translations[1].name,
            },
          ],
        })
        .then(() => {
          this.toast.success(this.$t("successfulSaveData"));
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    addTagRow() {
      this.tagList.push({
        translations: [{ name: null }, { name: null }],
      });
    },
    async removeTag(id) {
      if (id) {
        this.$http
          .delete(`/blog/tags/${id}`)
          .then(() => {
            this.toast.success(this.$t("successfulSaveData"));
            this.tagList = this.tagList.filter((tag) => tag.id !== id);
          })
          .catch(({ err }) => {
            if (err.response.data.status === 400) {
              this.toast.error(err.response.data.message);
            } else {
              this.toast.error(this.$t("NetworkError"));
            }
          });
      } else {
        this.tagList = this.tagList.pop();
      }
    },
  },
};
</script>

<style></style>
