<template>
  <main-layout>
    <h1 class="blogPage__h1">
      {{ $t("sidebarBlogNewsBtn") }}
    </h1>
    <div v-if="loginUser.role_id === 1" class="blogPage__tabs">
      <h2
        class="blogPage__h2"
        @click="blogView = true"
        :class="{ 'blogPage__h2-active': blogView }"
      >
        {{
          loginUser.role_id === 1 ? $t("Articles") : $t("sidebarBlogNewsBtn")
        }}
      </h2>
      <h2
        v-if="loginUser.role_id === 1"
        @click="blogView = false"
        class="blogPage__h2"
        :class="{ 'blogPage__h2-active': !blogView }"
      >
        {{ $t("Tags") }}
      </h2>
    </div>
    <div v-if="blogView" class="blogPage">
      <router-link
        :to="`/blog${
          loginUser.role_id === 1
            ? '/edit/' + latestPost.id
            : '/' + latestPost.slug
        }`"
        v-if="latestPost && loginUser.role_id !== 1"
        class="row blogPage__latestPost"
      >
        <div class="col-12 col-lg-6 col-xxl-7 h-100 p-0">
          <picture>
            <source
              type="image/webp"
              :srcset="`${pathToImage(latestPost.slug, latestPost.image)}.webp`"
            />
            <img
              :src="`${pathToImage(latestPost.slug, latestPost.image)}.png`"
              :alt="`${latestPost.slug} image`"
            />
          </picture>
        </div>
        <div class="col-12 col-lg-6 col-xxl-5">
          <div class="d-flex mt-10 mb-10">
            <div class="mr-15">
              <data-formatted
                date-format="dd.MM.yyyy"
                :data="latestPost.date"
              />
            </div>
            <div class="blogPage__latestPostAuthor">
              by
              <span class="text-decoration-underline"
                ><short-name :name="latestPost.expert_name"
              /></span>
            </div>
          </div>
          <div class="d-flex">
            <div
              v-for="tag in latestPost.tags"
              :key="tag.id"
              class="blogPage__tag"
            >
              {{ tag.name }}
            </div>
          </div>
          <div class="blogPage__latestPostTitle">
            {{ latestPost.title }}
          </div>
          <div>
            <div class="blogPage__latestPostText">
              <span v-html="latestPost.content"></span>
            </div>
            <base-button class="blogPage__latestPostBtn">
              {{
                loginUser.role_id === 1 ? $t("editExpertBtn") : $t("readMore")
              }}
            </base-button>
          </div>
        </div>
      </router-link>
      <h2 v-if="loginUser.role_id !== 1" class="blogPage__h2">
        {{ $t("latestArticle") }}
      </h2>
      <div class="experts__filterTitle">{{ $t("FilterByTag") }}</div>
      <div class="d-flex align-items-center justify-content-between mb-20">
        <div class="experts__filters">
          <div class="experts__filterButtonsContainer">
            <vue-multiselect
              class="servicesPage__select mr-15"
              :show-labels="false"
              :placeholder="$t('AllTopics')"
              label="name"
              openDirection="bottom"
              v-model="tag"
              :options="tags"
            />
            <div @click="clearFilter()" class="experts__filterReset">
              <reset-icon />
              {{ $t("resetFilter") }}
            </div>
          </div>
        </div>
        <div class="experts__searchInput">
          <search-input
            v-model="search"
            :search-function="searchArticles"
            @change="searchArticles"
            @keyup.enter="searchArticles"
            :placeholder="$t('searchArticle')"
          />
        </div>
      </div>
      <div class="row">
        <div v-for="post in blogPosts" class="col-lg-6 mb-20">
          <blog-card
            :upload-path="path"
            :edit="loginUser.role_id === 1"
            :post="post"
          />
        </div>
      </div>
      <div
        v-if="blogPosts && blogPosts.length === 10"
        class="w-max-content m-auto"
      >
        <base-button
          class="blogPage__loadMoreBtn"
          @click="loadMore()"
          :load="true"
          >{{ $t("loadMore") }}
        </base-button>
      </div>
    </div>
    <div v-else>
      <tags-page />
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SearchInput from "@/components/UI/inputs/SearchInput";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BlogCard from "@/components/elements/card/BlogCard";
import VueMultiselect from "vue-multiselect";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import ShortName from "@/components/components-helpers/ShortName";
import { mapGetters } from "vuex";
import TagsPage from "@/components/pages-templates/tags/TagsPage.vue";

export default {
  name: "BlogPages",
  components: {
    TagsPage,
    ShortName,
    DataFormatted,
    BlogCard,
    BaseButton,
    ResetIcon,
    SearchInput,
    MainLayout,
    VueMultiselect,
  },
  data() {
    return {
      path: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
      tag: null,
      search: null,
      limit: 10,
      blogView: true,
    };
  },
  mounted() {
    if (!this.blogPosts || !this.latestPost) this.getBlogPosts();
    if (this.tags.length === 0) this.getCategories();
  },
  methods: {
    clearFilter() {
      this.tag = null;
      this.search = null;
      this.getBlogPosts();
    },
    loadMore() {
      this.limit += 10;
      this.getBlogPosts();
    },
    getCategories() {
      this.$http.get("/blog/tags").then(({ data }) => {
        this.$store.commit("SET_BLOG_TAGS", data.body);
      });
    },
    searchArticles() {
      this.getBlogPosts();
    },
    getBlogPosts() {
      this.$http
        .get("/blog/posts", {
          params: {
            limit: this.limit,
            search: this.search ? this.search : null,
            tag: this.tag ? this.tag.id : null,
          },
        })
        .then(({ data }) => {
          if (data.body && data.body[0] && this.loginUser.role_id !== 1) {
            let latestPost = {
              content: data.body[0].translations[0].content
                ? data.body[0].translations[0].content
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .substring(0, 500) + "..."
                : "",
              slug: data.body[0].slug,
              expert_name: data.body[0].expert
                ? `${data.body[0].expert.first_name} ${data.body[0].expert.last_name}`
                : null,
              title: data.body[0].translations[0].title,
              image: data.body[0].image,
              date: data.body[0].date,
              id: data.body[0].id,
            };
            this.$store.commit("SET_BLOG_LATEST_POST", latestPost);
          }
          let blogItems = data.body;
          if (this.loginUser.role_id === 2 || this.loginUser.role_id === 4) {
            blogItems = blogItems.filter((item, index) => index !== 0);
          }
          this.$store.commit("SET_BLOG_ITEMS", blogItems);
        });
    },
    pathToImage(slug, imageName) {
      return `${this.path}/posts/${slug}/${imageName}-500`;
    },
  },
  computed: {
    ...mapGetters({
      tags: "BLOG_TAGS",
      blogPosts: "BLOG_ITEMS",
      latestPost: "BLOG_LATEST_POST",
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
  watch: {
    tag: {
      deep: true,
      handler() {
        this.getBlogPosts();
      },
    },
  },
};
</script>

<style></style>
